<script setup>
// Components
import DropZone from "@/components/report/DropZone.vue";
import FilePreview from "@/components/report/FilePreview.vue";

// Uploader
import uploadFiles from "@/components/report/file-uploader";
// File Management
import useFileList from "@/components/report/file-list";
const {
  reportFiles,
  templateFile,
  addReportFiles,
  setTemplateFile,
  removeReport,
  removeTemplate,
} = useFileList();

function onAddReport(e) {
  addReportFiles(e.target.files);
  e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
}
function onSelectTemplate(e) {
  setTemplateFile(e.target.files);
  e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
}
</script>

<template>
  <div class="container-lg">
    <h3 class="text-center m-4">RAPPORT</h3>
    <div class="fs-4">
      <p>
        Cet outil permet de copier des informations des anciens rapports vers un
        modèle de rapport que vous souhaitez utiliser.
      </p>
      <ol>
        <li>Glissez-déposez les anciens rapports qu'il faut copier</li>
        <li>
          Glissez-déposez le modèle de rapport avec des balises de type
          <span class="fst-italic">${}</span> où il faut copier le texte (ex:
          <span class="fst-italic"> ${localisation}</span>,
          <span class="fst-italic">${conclusion du rapport}</span>)
        </li>
        <li>
          Cliquez sur "Upload". Les rapports se téléchargent automatiquement à
          la fin du processing.
        </li>
      </ol>
    </div>
  </div>
  <div class="container-fluid" id="report">
    <div id="dragzone">
      <DropZone
        class="drop-area"
        @files-dropped="addReportFiles"
        #default="{ dropZoneActive }"
      >
        <label for="report-input">
          <span v-if="dropZoneActive">
            <span>Lacher ici</span>
            <span class="smaller">pour ajouter</span>
          </span>
          <span v-else>
            <span>Glisser-déposer les rapports ici</span>
            <span class="smaller">
              ou <strong><em>cliquer ici</em></strong> pour sélectionner vos
              fichiers <strong>(pdf, docx, doc)</strong>
            </span>
          </span>

          <input type="file" id="report-input" multiple @change="onAddReport" />
        </label>
        <ul class="image-list" v-show="reportFiles.length">
          <FilePreview
            v-for="file of reportFiles"
            :key="file.id"
            :file="file"
            tag="li"
            @remove="removeReport"
          />
        </ul>
      </DropZone>
      <DropZone
        class="drop-area"
        @files-dropped="setTemplateFile"
        #default="{ dropZoneActive }"
      >
        <label for="template-input">
          <span v-if="dropZoneActive">
            <span>Lacher ici</span>
            <span class="smaller">pour ajouter</span>
          </span>
          <span v-else>
            <span>Glisser-déposer votre template ici</span>
            <span class="smaller">
              ou <strong><em>cliquer ici</em></strong> pour sélectionner votre
              fichier <strong>(docx)</strong>
            </span>
          </span>

          <input type="file" id="template-input" @change="onSelectTemplate" />
        </label>
        <div class="file-preview-template">
          <FilePreview
            v-if="templateFile !== ''"
            :file="templateFile"
            tag="p"
            :oneFile="true"
            @remove="removeTemplate"
          />
        </div>
      </DropZone>
    </div>
    <button
      @click.prevent="uploadFiles(reportFiles, templateFile)"
      class="btn btn-lg btn-dark m-4"
    >
      Upload
    </button>
  </div>
</template>

<style lang="scss" scoped>
#report {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
#dragzone {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  width: 100%;
}

.drop-area {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  margin: 10px;
  background: #ffffff55;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: 0.2s ease;
  border-radius: 30px;

  &[data-active="true"] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: #ffffffcc;
  }
}

label {
  font-size: 36px;
  cursor: pointer;
  display: block;

  span {
    display: block;
  }

  input[type="file"]:not(:focus-visible) {
    // Visually Hidden Styles taken from Bootstrap 5
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  .smaller {
    font-size: 16px;
  }
}

.image-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
}

.upload-button {
  display: block;
  appearance: none;
  border: 0;
  border-radius: 50px;
  padding: 0.75rem 3rem;
  margin: 1rem auto;
  font-size: 1.25rem;
  font-weight: bold;
  background: #369;
  color: #fff;
  text-transform: uppercase;
}

button {
  cursor: pointer;
}
</style>
