const reportsTemplateURL = import.meta.env.DR_REPORTS_API_URL;

export default async function uploadsFile(reports, template) {
  // set up the request data
  let formData = new FormData();
  reports.forEach((file) => {
    formData.append("reports", file.file);
    file.status = "loading";
  });
  formData.append("template", template.file);
  template.status = "loading";

  let response = await fetch(reportsTemplateURL, {
    method: "POST",
    body: formData,
  });

  reports.forEach((file) => {
    file.status = response.ok;
  });
  template.status = response.ok;
  if (response.ok) {
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "reports.zip";

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } else {
    console.error("Failed to fetch the file:", response.statusText);
  }
  return response;
}
