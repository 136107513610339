import { ref } from "vue";

export default function () {
  const reportFiles = ref([]);
  const templateFile = ref("");
  const ext_supported_report = ["pdf", "doc", "docx"];
  const ext_supported_template = ["docx"];
  function addReportFiles(newFiles) {
    const newUploadableFiles = [...newFiles]
      .filter((file) =>
        ext_supported_report.includes(file.name.split(".").pop())
      )
      .map((file) => new UploadableFile(file))
      .filter((file) => !fileExists(file.id));
    reportFiles.value = reportFiles.value.concat(newUploadableFiles);
  }

  function setTemplateFile(newFile) {
    const file = newFile[0];
    if (ext_supported_template.includes(file.name.split(".").pop())) {
      const newUploadableFile = new UploadableFile(file);
      templateFile.value = newUploadableFile;
    }
  }
  function fileExists(otherId) {
    return reportFiles.value.some(({ id }) => id === otherId);
  }

  function removeTemplate() {
    templateFile.value = "";
  }

  function removeReport(file) {
    const index = reportFiles.value.indexOf(file);
    if (index > -1) reportFiles.value.splice(index, 1);
  }

  return {
    reportFiles,
    templateFile,
    addReportFiles,
    setTemplateFile,
    removeReport,
    removeTemplate,
  };
}

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.icon = `icons/${file.name.split(".").pop()}.png`;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}
